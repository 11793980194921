#team {
  .teamContainer {
    padding: 50px 0;
    margin: 20px 0;
    background-color: white;
  }

  .teamWidth {
    width: 75%;
    margin: 20px auto;
  }

  h1 {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 700;
    font-size: 28px;
    margin: 0;
  }

  h3 {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 700;
    font-size: 16px;
    margin: 0 0 2px 0;
    transition: .2s;
  }

  .textHover {
    text-decoration: underline;
  }

  .textHover:hover {
    color: #FFCD4D;
    cursor: pointer;
  }

  p {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
  }

  .line {
    border-bottom: solid 3px #f2f2f2;
    padding-top: 8px;
    margin-bottom: 25px;
  }

  // Scroll Fix

  #directors {
    padding-top: 100px;
    margin-top: -100px;
  }

  #advisors {
    padding-top: 100px;
    margin-top: -100px;
  }

  #staff {
    padding-top: 100px;
    margin-top: -100px;
  }
}

.popupContainer {
  background-color: white;
  color: black;

  border: 1px solid black;


  margin: 0 auto;
  padding: 35px;

  h2 {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 700;
    font-size: 28px;
    margin: 0 0 4px 0;
  }
}

.popup-content {
  max-height: 600px;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.popup-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.popup-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}