#questions {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  padding: 50px 0 160px 0;

  h1 {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 700;
    font-size: 28px;
  }

  p {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 400;
    font-size: 16px;
  }

  .learnButton {
    font-family: "Tinos", serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    display: inline-block;
    transition: 0.2s !important;
    z-index: 1;

    padding: 10px 25px !important;
    margin-top: 10px;
    border-radius: 0;
    color: black;
    background-color: #ffcd4d;
  }
}

@media (max-width: 768px) {
  #questions {
    padding: 10px 0 80px 0;
  }
}
