#faq {
  width: 90%;
  margin: 0 auto;
  padding: 50px 0;

  .aboutImage {
    width: 100%;
    max-width: 500px;
    max-height: 500px;


    height: auto;
    margin-bottom: 25px;
  }

  .aboutImage2 {
    width: 90%;
    // max-width: 550px;
    // height: 800px;
    margin-bottom: 25px;
    // object-fit: cover;
  }

  h1 {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 700;
    font-size: 28px;
  }

  p {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
  }

  .faqContainer {
    max-width: 550px;
  }

  .accordion-item {
    background-color: transparent;
    margin-bottom: 20px;
    border: none;
  }

  .accordion-button {
    background-color: white;
    color: black;
    border-radius: 7px !important;
    padding: 1.3em 1.3em;

    font-size: 16px;
    font-weight: 400;
  }

  .accordion-button:not(.collapsed) {
    color: #0e2b57;
    background-color: white;
    box-shadow: none;
    border: none;
    outline: none;
  }

  .accordion-button:active,
  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
  }
}

@media (max-width: 768px) {
  #faq {
    padding: 40px 0;

    .aboutImage {
      margin-top: 25px;
    }

    .aboutImage {
      width: 100%;
      max-width: unset;
      height: auto;
      margin-bottom: 25px;
    }

    .aboutImage2 {
      width: 100%;
    }
  }
}