#donation {
  background-color: #0d0c0c;

  .donateContainer {
    width: 80%;
    margin: 0 auto;
  }

  h1 {
    font-family: "Tinos", serif !important;
    color: white;
    font-weight: 700;
    font-size: 28px;
  }

  p {
    font-family: "Tinos", serif !important;
    color: white;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
  }

  .donateButton {
    font-family: "Tinos", serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    display: inline-block;
    transition: 0.2s !important;
    z-index: 1;

    padding: 10px 35px !important;
    margin-top: 10px;
    border-radius: 0;
    color: white;
    border: 2px solid #ffcd4d;
    border-radius: 70px;
  }

  .donateButton:hover {
    color: black;
    background-color: #ffcd4d;
    border: 2px solid #ffcd4d;
  }

  .donateImage {
    width: 100%;
    height: 100%;
    max-width: 450px;
    object-fit: cover;
  }

  .donateText {
    padding: 60px 0;
    max-width: 450px;
  }

  .text-right {
    text-align: right;
  }

  .textIndent {
    border-left: solid #242121 5px;
    padding-left: 20px;
  }
}

@media (max-width: 768px) {
  #donation {
    .donateImage {
      width: 100%;
      height: auto;
      max-width: unset;
    }

    .donateContainer {
      width: 100%;
      margin: 0 auto;
    }

    .rmpadding {
      padding: 0 !important;
    }
  }
}