@import url("https://fonts.googleapis.com/css2?family=Tinos:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Tinos:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Tinos", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8 !important;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
p {
  font-family: "Tinos", serif !important;
  margin: 0;
}

.flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}