#navbar {
  position: relative;
  z-index: 999;

  // -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  // -moz-animation: fadein 2s; /* Firefox < 16 */
  // -ms-animation: fadein 2s; /* Internet Explorer */
  // -o-animation: fadein 2s; /* Opera < 12.1 */
  // animation: fadein 2s;
  // animation-fill-mode: forwards;

  .container-fluid {
    padding: 0 !important;
  }

  .navlogo {
    width: 190px;
    height: auto;
    margin-right: 20px;
  }

  .bg-custom {
    padding: 50px 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    transition: 0.4s;
  }

  .navbarWidth {
    width: 80%;
    margin: 0 auto;
    max-width: 1400px;
  }

  .navbarScroll {
    padding: 20px 0;
    background-color: black;
    transition: 0.4s;
    // border-bottom: solid 1.5px rgba($color: white, $alpha: 0);
  }

  .nav-link {
    font-family: "Tinos", serif !important;
    color: white;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700 !important;
  }

  .nav-link:hover {
    color: #ffcd4d;
  }

  .donateButton {
    font-family: "Tinos", serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    display: inline-block;
    transition: 0.2s !important;
    z-index: 1;

    padding: 10px 40px !important;
    border-radius: 70px;
    margin-top: 0.5em;
    color: white;
    border: 2px solid #ffcd4d;
  }

  .donateButton:hover {
    color: black;
    background-color: #ffcd4d;
    border: 2px solid #ffcd4d;
  }

  .active {
    color: #ffcd4d;
  }
}

@media (max-width: 768px) {
  #navbar {
    .navbarWidth {
      width: 90%;
      margin: 0 auto;
      max-width: 1400px;
    }

    .bg-custom {
      padding: 20px 0;
      background-color: black;
    }

    .navlogo {
      width: 150px;
      height: auto;
      margin-right: 20px;
    }
  }
}

/* Key Frames */

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// Other

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .dropdown-menu {
    display: table-cell;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}