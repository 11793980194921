#footer {
  background-color: #0d0c0c;
  padding: 60px 0 30px 0;
  margin: 0 auto;

  .footerContainer {
    width: 80%;
    margin: 0 auto;
  }

  h3 {
    font-family: "Tinos", serif !important;
    color: #ffcd4d;
    font-size: 14px;
    font-weight: 700 !important;
    text-align: left;
  }

  .rights {
    color: #808080;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .col-right {
    text-align: right;
  }

  .socialIcon {
    margin-left: 20px;
  }

  .socialIcon:hover {
    cursor: pointer;
  }

  li {
    font-family: "Tinos", serif !important;
    list-style: none;
  }

  .footer-link {
    font-family: "Tinos", serif !important;
    font-size: 15px;
    font-weight: 400 !important;
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
  }

  .footerLogo {
    width: 150px;
    height: auto;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  #footer {
    padding: 15px 0 40px 0;

    .col-right {
      text-align: left;
    }

    .socialIcon {
      margin-left: 0;
      margin-right: 20px;
    }

    .footerSpacing {
      margin-top: 40px;
    }
  }
}

@media (max-width: 992px) {
  #footer {
    .col-right {
      text-align: left;
    }

    .socialIcon {
      margin-left: 0;
      margin-right: 20px;
    }

    .footerSpacing {
      margin-top: 40px;
    }
  }
}

@media (max-width: 1200px) {
  #footer {
    .col-right {
      text-align: left;
    }

    .socialIcon {
      margin-left: 0;
      margin-right: 20px;
    }

    .footerSpacing {
      margin-top: 40px;
    }
  }
}
