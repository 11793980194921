#testimonials {
  background-color: white;
  padding: 80px 0;

  .quoteContainer {
    width: 80%;
    margin: 0 auto;
  }

  h1 {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 12px;

    text-align: center;
  }

  h2 {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 700;
    font-size: 22px;
  }

  p {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
  }

  .testimonialCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    background-color: #f8f8f8;
    margin: 0 8px;
    padding: 25px;
    border-radius: 7px;

    box-shadow: rgba(149, 157, 165, 0.1) 0px 4px 4px;
    border: 1px solid rgba($color: #000000, $alpha: .05);
  }
}

@media (max-width: 992px) {
  #testimonials {
    padding: 50px 0;

    .mvSpacing {
      margin-top: 25px;
      margin-bottom: 25px;
    }

    .testimonialCard {
      min-height: 230px;
    }
  }
}

// Video

#video {
  width: 80%;
  margin: 0 auto;
  padding: 70px 0;

  .react-player {
    margin: 0 auto;
    max-width: 700px;
  }

  @media (max-width: 768px) {
    padding: 40px 0;

    .react-player {
      margin: 0 auto;
      max-width: 700px;
      max-height: 250px;
    }
  }
}