#newsletter {
  width: 100%;
  padding: 20px 0;
  background-color: white;
  margin: 0 auto;

  .newsletterContainer {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .inputContainer {
    display: inline-flex;
    gap: 15px;
  }

  h1 {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 700;
    font-size: 28px;
    margin: 0;
  }

  p {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 400;
    font-size: 16px;
  }

  .learnButton {
    font-family: "Tinos", serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    display: inline-block;
    transition: 0.2s !important;
    z-index: 1;

    padding: 10px 25px !important;
    border-radius: 0;
    color: black;
    background-color: #ffcd4d;
    margin-bottom: 4px;
    margin-left: 10px;
    height: 48px;
  }

  input {
    background-color: #f8f8f8;
    border: 2px transparent solid;
    padding: 10px 55px 10px 15px;
    transition: 0.2s;
  }

  input:focus {
    background-color: #f8f8f8;
    border: 2px #ffcd4d solid;
    outline: none !important;
  }

  input::placeholder {
    color: #bbbbbb;
  }
}

@media (max-width: 905px) {
  #newsletter {
    text-align: center;
    padding: 30px 0;

    .newsletterContainer {
      width: 90%;
      margin: 0 auto;
      display: unset;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      text-align: center;
    }

    .inputContainer {
      display: inline-flex;
      gap: 15px;
      margin-top: 15px;
    }

    input {
      background-color: #f8f8f8;
      border: 2px transparent solid;
      padding: 10px 15px 10px 15px;
      transition: 0.2s;
    }
  }
}