#gallery {
    width: 80%;
    margin: 0 auto;
    padding: 15px 0 25px 0;
    text-align: center;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;

    img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        margin: 0 auto;

        background-color: black;
    }

    img:hover {
        cursor: pointer;
    }

    @media (max-width: 978px) {
        grid-template-columns: 1fr 1fr 1fr;
        width: 90%;

        img {
            height: 200px;
        }
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        width: 90%;

        img {
            height: 200px;
        }
    }
}