#donate {
  width: 90%;
  margin: 0 auto;
  padding: 50px 0;

  .aboutImage {
    width: 100%;
    max-width: 450px;
    height: auto;
    margin-bottom: 25px;
  }

  h1 {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 700;
    font-size: 28px;
  }

  p {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
  }

  .donateContainer {
    display: flex;
    flex-direction: row;
    gap: 25px;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    padding: 35px 0;
    max-width: 550px;
  }

  .donateButton {
    font-family: "Tinos", serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    display: inline-block;
    transition: 0.2s !important;
    z-index: 1;

    padding: 9px 40px !important;
    margin-top: 10px;
    border-radius: 999px;
    color: black;
    border: black solid 2px;
    background-color: transparent;

    max-width: 150px;
  }

  .donateButton:hover {
    color: white;
    border: black solid 2px;
    background-color: black;
  }

  .donateButton:focus {
    outline: none;
    box-shadow: none;
  }

  input {
    background-color: white;
    border: 2px transparent solid;
    padding: 10px 15px;
    width: 100%;
    transition: 0.2s;
    margin: 10px 0;
  }

  input:focus {
    background-color: white;
    border: 2px #ffcd4d solid;
    outline: none !important;
  }

  input::placeholder {
    color: #bbbbbb;
  }

  .rmborder {
    border-bottom: none;
  }

  .founderMessage {
    border-top: 2px solid #e2e2e2;
    text-align: left;
    max-width: 450px;
    margin: 0 auto;
    padding: 20px 0;
  }

  .aboutImage2 {
    width: 95%;
    // max-width: 550px;
    // height: 800px;
    margin-bottom: 25px;
    // object-fit: cover;
  }
}

@media (max-width: 768px) {
  #donate {
    padding: 40px 0;

    .aboutImage {
      margin-top: 25px;
    }

    .aboutImage {
      width: 100%;
      max-width: unset;
      height: auto;
      margin-bottom: 25px;
    }

    .aboutImage2 {
      width: 100%;
      // max-width: 550px;
      // height: 800px;
      margin-bottom: 25px;
      // object-fit: cover;
    }
  }
}