#about {
  width: 90%;
  margin: 0 auto;
  padding: 100px 0;

  .learnButton {
    font-family: "Tinos", serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    display: inline-block;
    transition: 0.2s !important;
    z-index: 1;

    padding: 10px 25px !important;
    margin-top: 10px;
    border-radius: 0;
    color: black;
    background-color: #ffcd4d;
  }

  .aboutImage {
    width: 100%;
    max-width: 450px;
    height: auto;
    margin-bottom: 25px;
  }

  .aboutImage2 {
    width: 90%;
    // max-width: 550px;
    height: 600px;
    margin-bottom: 25px;
    object-fit: cover;
  }

  .aboutImage3 {
    width: 90%;
    // max-width: 550px;
    // height: 800px;
    margin-bottom: 25px;
    object-fit: cover;
  }

  .aboutText {
    max-width: 550px;
  }

  .v-center {
    align-items: center;
  }

  h1 {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 700;
    font-size: 28px;
  }

  p {
    font-family: "Tinos", serif !important;
    color: black;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
  }

  .textIndent {
    border-left: solid #f2f2f2 5px;
    padding-left: 20px;
  }
}

@media (max-width: 768px) {
  #about {
    padding: 40px 0;

    .aboutImage {
      margin-top: 25px;
    }

    .aboutImage2 {
      width: 100%;
      margin-top: 10px;
      height: 400px;
    }

    .aboutImage {
      width: 100%;
      max-width: unset;
      height: auto;
      margin-bottom: 25px;
    }

    .aboutText {
      max-width: unset;
      padding: 10px 0;
    }
  }
}

.changePadding {
  padding: 50px 0 !important;
}

@media (max-width: 768px) {
  .changePadding {
    padding: 30px 0 !important;
  }
}