#landing {
  height: 100vh;
  width: 100%;

  .heroBackground {
    height: 100vh;
    background: url(../../Images/flags.jpeg), black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 0.2s;
    background-attachment: fixed;
  }

  .heroContainer {
    margin: 0 auto;
    width: 80%;
    padding-top: 250px;
  }

  .landingBox {
    background-color: rgba($color: black, $alpha: .5);
    max-width: 700px;
    padding: 25px;
    border: 1px solid rgba($color: white, $alpha: .75);
    border-radius: 7px;

    backdrop-filter: blur(4px) opacity(1);
    -webkit-backdrop-filter: blur(4px) opacity(1);
  }

  h1 {
    font-family: "Tinos", serif !important;
    color: white;
    font-weight: 700;
    font-size: 50px;
  }

  p {
    font-family: "Tinos", serif !important;
    color: white;
    font-weight: 400;
    font-size: 24px;
    margin-top: 15px;
  }

  .pContainer {
    border-left: solid white 1px;
    padding-left: 20px;
    max-width: 650px;
  }

  .donateButton {
    font-family: "Tinos", serif !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    display: inline-block;
    transition: 0.2s !important;
    z-index: 1;

    padding: 10px 45px !important;
    border-radius: 70px;
    margin-top: 15px;
    color: white;
    border: 2px solid white;
  }

  .donateButton:hover {
    color: black;
    border: 2px solid white;
    background-color: white;
  }

  @media (max-width: 768px) {
    height: 480px;

    .heroContainer {
      margin: 0 auto;
      width: 90%;
      padding-top: 120px;
    }

    h1 {
      font-size: 34px;
    }

    p {
      font-size: 18px;
    }

    .heroBackground {
      height: 100%;
      background: url(../../Images/flags.jpeg), black;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 0.2s;
      background-attachment: unset;
    }
  }
}